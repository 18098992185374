var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',_vm._l((_vm.labels),function(i,idx){return _c('th',{key:i.label,staticClass:"whitespace-nowrap",class:[{ 'cursor-pointer': i.isSort }, _vm.customClass],on:{"click":function($event){return _vm.changeSort(idx, i)}}},[_vm._v(" "+_vm._s(i.label)+" "),(i.isSort)?_c('div',{staticClass:"w-[20px] inline-block"},[(
                        _vm.orderByOption.orderBy == '' ||
                        _vm.orderByOption.sortBy !== i.val
                    )?_c('span',[_c('i',{staticClass:"text-lg text-gray-800 icofont-long-arrow-up"}),_c('i',{staticClass:"py-1 -ml-3 text-lg text-gray-800 icofont-long-arrow-down"})]):_vm._e(),(
                        _vm.orderByOption.orderBy == 'asc' &&
                        _vm.orderByOption.sortBy == i.val
                    )?_c('i',{staticClass:"py-1 mr-1 text-lg text-orange-600 icofont-long-arrow-up"}):_vm._e(),(
                        _vm.orderByOption.orderBy == 'desc' &&
                        _vm.orderByOption.sortBy == i.val
                    )?_c('i',{staticClass:"py-1 text-lg text-orange-600 icofont-long-arrow-down"}):_vm._e()]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }