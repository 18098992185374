<template>
    <div>
        <LoadingComponent :isLoading="loading" />

        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <TableHead ref="TableHead" :labels="tableLabels" @changeSort="changeSort" />
                <tbody>
                    <tr v-for="item in datas" :key="item.month" class="text-center duration-200 transition">
                        <td>{{ `${formatMonth(item.month).year}/${formatMonth(item.month).month}` }} 款項</td>
                        <td>$ {{ item.commission | formatCurrency }}</td>
                        <td class="cursor-pointer" @click="goPaymentList(formatMonth(item.month).year, formatMonth(item.month).month)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// init search Filter options
const defaultFilterOpts = {
    year: "", // created_at or started_at or gross_price
    minth: "", // asc or desc
};
export default {
    name: "ProviderList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        // 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // table 標籤
        tableLabels() {
            const tableLabels = [
                {
                    label: "月結款項",
                },
                {
                    label: "金額",
                },
                {
                    label: "查看",
                },
            ];
            return tableLabels;
        },
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            return {
                ...filter,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 活動選項 options
        payStatusOptions() {
            const result = [
                {
                    label: "上架",
                    value: 1,
                },
                {
                    label: "未上架",
                    value: 0,
                },
            ];
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "", // 服務商id
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
        };
    },
    methods: {
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
        },
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchTransactionsByBrokers(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        formatMonth(val) {
            return {
                year: val.slice(0, 4),
                month: val.slice(-2),
            };
        },
        goPaymentList(year, month) {
            this.$router.push({
                name: "payment_detail_list",
                params: {
                    brokerId: this.id,
                    year,
                    month,
                },
            });
        },
        /**
         * 發送客服註記
         * @param { type String (字串) } val 註記內容
         */
        async sendNote(val) {
            this.sendNoteloading = true;
            try {
                await this.$api.CreateNotesApi(this.$route.params.id, val);
                this.sendNoteloading = false;
                this.note = null;
                this.$message({
                    type: "success",
                    message: "新增客服註記成功",
                });
                // 新增完後 回傳給父組件 取得最新列表
                this.$emit("updateNotesList", true);
            } catch (err) {
                this.sendNoteloading = false;
                this.$message({
                    type: "error",
                    message: "新增客服註記失敗",
                });
            }
        },
    },
    activated() {
        if (this.id) {
            this.getList();
            this.$emit("updateBreadCrumb", "款項資訊");
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getList();
            this.$emit("updateBreadCrumb", "款項資訊");
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.evaluation-area {
    display: flex;
    padding: 20px;
    .total {
        text-align: center;
        border-right: solid 1px #ccc;
        flex: 1;
    }
    .comment {
        flex: 1;
        text-align: center;
        border-right: solid 1px #ccc;
    }
    .detail {
        flex: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        .comment-star-total {
            flex: 1;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            .fa-star {
                font-size: 12px;
                margin-left: 0px;
                margin-bottom: 2px;
                color: #ff5733 !important;
            }
        }
    }
    .score {
        font-size: 30px;
        font-weight: 500;
    }
    .fa-star {
        margin-left: 5px;
        color: #ff5733 !important;
    }
}

.edit {
    text-decoration-line: underline;
    cursor: pointer;
}

.table-score {
    text-align: left;
    padding-left: 30px;
    .fa-star {
        color: #ff5733;
    }
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}

::v-deep .el-message-box__message {
    word-wrap: break-word !important;
}
</style>
